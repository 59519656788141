<template>
  <div>
    <iframe :src="frame" width="100%" height="1200px" frameborder="0"></iframe>
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import { jsPDF } from 'jspdf'
import { useRouter } from '@core/utils'
import { i18n } from '@/plugins/i18n'
import fontKR from './fontKR.json'
import fontBlodKR from './fontBoldKR.json'
import { currentCurrency, formatPrice, sumdate } from '@/services/filters'
import 'jspdf-autotable'
import { api } from "@/services/api"

export default {
  setup() {
    const { route } = useRouter()
    const fonts = ref(fontKR)
    const fontsBold = ref(fontBlodKR)
    const dataTableList = ref([])
    const fontTitle = ref(14)
    const fontBody = ref(13)
    const lastTable = ref(0)
    const shop_print_type = ref(0)
    const shopData = ref({})
    const frame = ref('')
    onMounted(() => {
      shopData.value = JSON.parse(localStorage.getItem('shop'))
      api.get({
        path: '/printOrder',
        param: `?order_id_list=${ route.value.query.id }&lang=${ route.value.query.lang }&currency_id=${ localStorage.getItem('currencyId') }`,
      }).then(({ data }) => {
        savePDF(data)
      }).catch((err) => {
        console.log(err)
      })
    })
    const headers = [
      `목록`,
      '가격',
      '수량',
      '할인',
      '함께',
    ]
    const savePDF = data => {
      const doc = new jsPDF({
        orientation: 'portrait',
        format: 'a4',

      })

      doc.setProperties({
        title: `영수증 인쇄`,
      })

      doc.addFileToVFS('-23-normal.ttf', fonts.value.data)
      doc.addFont('-23-normal.ttf', '-23', 'normal')
      doc.addFileToVFS('-23-bold.ttf', fontsBold.value.data)
      doc.addFont('-23-bold.ttf', '-23', 'bold')
      doc.setFont('-23', 'bold')
      for (let i = 0; i < data.length; i++) {
        doc.autoTable({
          theme: 'plain',
          styles: {
            font: '-23',
            fontStyle: 'bold',
            textColor: '#212121',
            fontSize: fontBody.value,
            halign: 'left',
          },
          showFoot: 'lastPage',
          headStyles: {
            // textColor: '#1E88E5',
            fontStyle: 'bold',
            fontSize: fontTitle.value,
            halign: 'center',
          },
          columnStyles: {
            1: { halign: 'right', fillColor: '#F5F5F5', cellWidth: 30 },
            2: { halign: 'right', cellWidth: 30 },
            3: { halign: 'right', fillColor: '#F5F5F5', cellWidth: 30 },
            4: { halign: 'right', cellWidth: 30 },
          },
          startY: 75,
          margin: {
            top: 75,
            left: 5,
            right: 5,
            bottom: 80,
          },

          head: [headers],
          body: data[i].order_detail_arr,
          didDrawPage(datax) {
            if (shopData.value.shop_image) {
              doc.addImage(shopData.value.shop_image, 'JPEG', 10, 5, 23, 23)
            }
            doc.setFontSize(fontTitle.value)
            doc.setFillColor('#212121')
            doc.text(shopData.value.shop_name, 40, 10)
            doc.setFont('-23', 'normal')
            doc.setFontSize(fontBody.value)
            doc.text(shopData.value.shop_address_ko, 40, 15,)
            doc.text(`세금 식별 번호 ${ shopData.value.shop_tax_number }`, 40, 20,)
            doc.text(`전화 번호 ${ shopData.value.shop_phone } Fax : ${ shopData.value.shop_fax_number }`, 40, 25,)
            doc.setLineWidth(0.8)
            // doc.setDrawColor('#1E88E5')
            doc.line(61, 30, 205, 30)
            doc.setDrawColor('#212121')
            doc.line(4, 30, 60, 30)
            doc.setLineWidth(0.1)
            doc.setDrawColor('#212121')
            doc.line(4, 75, 205, 75)
            doc.line(4, 84, 205, 84)

            doc.setFont('-23', 'bold')
            doc.setFontSize(fontTitle.value)
            doc.text(`고객 ${ data[i].order_transportation_name }`, 5, 40,)
            doc.setFont('-23', 'normal')
            doc.setFontSize(fontBody.value)
            doc.text(`${ data[i].order_transportation_full_address }`, 5, 45)
            doc.text(`전화 번호 ${ data[i].order_transportation_phone }`,
                5,
                50,
            )
            doc.setFont('-23', 'bold')
            doc.setFontSize(fontTitle.value)
            // doc.setTextColor('#1565C0')
            doc.text(`영수증`, 204, 40, null, null, 'right')
            doc.text('[RECEIPT]', 204, 45, null, null, 'right')
            // doc.setDrawColor(0)
            // doc.setFillColor('#1E88E5')
            // doc.rect(106, 58, 99, 16, 'F')
            // doc.setTextColor('#fff')
            doc.setFont('-23', 'bold')
            doc.setFontSize(fontTitle.value)
            doc.text(`숫자`, 130, 64, null, null, 'left')
            doc.text(`데이트`, 170, 64, null, null, 'left')
            doc.text(`${ data[i].order_code }`, 130, 71, null, null, 'left')
            doc.text(`${ data[i].create_date_text }`, 170, 71, null, null, 'left')
            // doc.setTextColor('#1E88E5')
            const str = `페이지 ${ doc.internal.getNumberOfPages() }`
            doc.setFontSize(10)
            doc.text(str, 189, 10)

            lastTable.value = datax.cursor.y
            doc.setFontSize(fontTitle.value)
            doc.setTextColor('#212121')
            doc.setFont('-23', 'bold')
            doc.text('노트', 5, lastTable.value + 10 + 8 * 0,)

            doc.setFont('-23', 'bold')
            // doc.setTextColor('#1E88E5')
            doc.setFontSize(fontTitle.value)
            const arr = [
              { name: '총 가격', value: data[i].order_before_discount },
              { name: '할인', value: data[i].order_discount },
              { name: '할인 후 총 가격', value: data[i].order_after_discount },
              { name: `큰 통 ${ data[i].order_vat }%`, value: data[i].order_vat_value },
              { name: '화물', value: data[i].order_transportation_total },
              { name: '정가', value: data[i].sum_total_transportation }
            ]
            // doc.setDrawColor(0)
            // doc.setFillColor('#1E88E5')
            // doc.rect(119, lastTable.value + 10 + 8.4 * 4, 86, 10, 'F')
            for (let i = 0; i < arr.length; i++) {
              const x = 8
              if (arr[i].name === '정가') {
                doc.setFont('-23', 'bold')
                // doc.setTextColor('#fff')
              }
              doc.text(arr[i].name, 120, lastTable.value + 10 + x * i,)
              doc.text(`${ formatPrice(arr[i].value) } ${ currentCurrency() }`, 201, lastTable.value + 10 + x * i, null, null, 'right')
            }

          },
        })
        doc.addPage(1)
      }
      doc.deletePage(data.length + 1)
      frame.value = doc.output('datauristring', { filename: `RECEIPT.pdf` })
    }

    return { savePDF, frame }
  },
}
</script>
